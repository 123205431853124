module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://filcon-sweden.se/api/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Filcon SE","short_name":"Filcon SE","start_url":"/","lang":"en","display":"standalone","icon":"src/assets/images/favicon.svg","icon_options":{"purpose":"any maskable"},"theme_color":"#151515","background_color":"#151515","localize":[{"start_url":"/ru/","lang":"ru","name":"Filcon Sweden","short_name":"Filcon Sweden"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6131ddf1e47844f71d216bab832c8f2a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"runtimeCaching":[{"handler":"NetworkFirst","options":{"cacheName":"all-pages"}}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
